<template>
  <footer class="footer has-background-rlgray">
    <div class="container">
      <div class="columns is-multiline">
        <div class="column is-half is-4-desktop">
          <logo-svg class="footer-logo" :preserve-aspect-ratio.camel="'xMinYMid'" />
          <div class="footer-link">
            <p>Rahalaitos.fi on suomalainen lainanvälityspalvelu, joka on toiminut lainanhakijoiden apuna jo vuodesta 2011 lähtien.</p>
            <p>Kotimaisena alan asiantuntijana Rahalaitos.fi on ollut edelläkävijänä kehittämässä asiakkailleen entistä parempia mahdollisuuksia lainan hakemiseen ja lainojen kilpailuttamiseen.</p>
          </div>
        </div>
        <div class="column is-half is-7-desktop is-offset-1-desktop">
          <div class="columns">
            <div class="column is-7">
              <p class="footer-link-title">Asiakaspalvelu</p>
              <p class="footer-link"><a href="tel:0923113670">09 2311 3670</a></p>
              <p class="footer-link">
                <a href="mailto:asiakaspalvelu@rahalaitos.fi">asiakaspalvelu@rahalaitos.fi</a>
              </p>
              <p class="footer-link">(ma-pe 9.00-16.00)</p>
              <p class="footer-link-title">Muut tiedustelut</p>
              <p class="footer-link">
                <a href="mailto:info@rahalaitos.fi">info@rahalaitos.fi</a>
              </p>
            </div>
            <div class="column">
              <p class="footer-link-title">Osoite</p>
              <p class="footer-link">Urho Kekkosen Katu 7 B</p>
              <p class="footer-link">00100 Helsinki</p>
              <p class="footer-link-title">Y-tunnus</p>
              <p class="footer-link">2521965-8</p>
              <aaa-svg v-if="!showLinks" class="footer-aaa_svg footer-aaa_svg-top" />
            </div>
          </div>
        </div>
        <div v-if="showLinks" class="column is-half is-4-tablet">
          <p class="footer-link-title">Lainat</p>
          <div class="columns">
            <div class="column">
              <p class="footer-link">
                <nuxt-link to="/lainaa">Lainaa</nuxt-link>
              </p>
              <p class="footer-link">
                <nuxt-link to="/yhdista-lainat">Yhdistä lainat</nuxt-link>
              </p>
              <p class="footer-link">
                <nuxt-link to="/lainaa-ilman-vakuuksia">Lainaa ilman vakuuksia</nuxt-link>
              </p>
              <p class="footer-link">
                <nuxt-link to="/lainaa-ilman-takaajia">Lainaa ilman takaajia</nuxt-link>
              </p>
              <p class="footer-link">
                <nuxt-link to="/lainaa-netista">Lainaa netistä</nuxt-link>
              </p>
              <p class="footer-link">
                <nuxt-link to="/pikalaina">Pikalaina</nuxt-link>
              </p>
              <p class="footer-link">
                <nuxt-link to="/autolaina">Autolaina</nuxt-link>
              </p>
              <p class="footer-link">
                <nuxt-link to="/kilpailuta-lainat">Kilpailuta lainat</nuxt-link>
              </p>
              <p class="footer-link">
                <nuxt-link to="/kokemuksia">Kokemuksia</nuxt-link>
              </p>
              <p class="footer-link">
                <nuxt-link to="/yhteistyokumppanit">Yhteistyökumppanit</nuxt-link>
              </p>
              <p class="footer-link">
                <nuxt-link to="/yhdistelylaina">Yhdistelylaina</nuxt-link>
              </p>
            </div>
            <div class="column">
              <p class="footer-link">
                <nuxt-link to="/parhaat-lainatarjoukset">Parhaat lainatarjoukset</nuxt-link>
              </p>
              <p class="footer-link">
                <nuxt-link to="/eroon-pienlainoista">Eroon pienlainoista</nuxt-link>
              </p>
              <p class="footer-link">
                <nuxt-link to="/remonttilaina">Remonttilaina</nuxt-link>
              </p>
              <p class="footer-link">
                <nuxt-link to="/lainavertailu">Lainavertailu</nuxt-link>
              </p>
              <p class="footer-link">
                <nuxt-link to="/kulutusluotto">Kulutusluotto</nuxt-link>
              </p>
              <p class="footer-link">
                <nuxt-link to="/edullinen-laina">Edullinen Laina</nuxt-link>
              </p>
              <p class="footer-link">
                <nuxt-link to="/lainahakemus">Lainahakemus</nuxt-link>
              </p>
              <p class="footer-link">
                <nuxt-link to="/lainapalvelu">Lainapalvelu</nuxt-link>
              </p>
              <p class="footer-link">
                <nuxt-link to="/asuntolainat">Asuntolainat</nuxt-link>
              </p>
              <p class="footer-link">
                <nuxt-link to="/lainalaskuri">Lainalaskuri</nuxt-link>
              </p>
            </div>
          </div>
        </div>
        <div v-if="showLinks" class="column is-half is-4-tablet  is-offset-1-desktop">
          <p class="footer-link-title">Lainasummat</p>
          <p class="footer-link">
            <nuxt-link to="/lainaa-5000-euroa">Lainaa 5 000 euroa</nuxt-link>
          </p>
          <p class="footer-link">
            <nuxt-link to="/lainaa-10000-euroa">Lainaa 10 000 euroa</nuxt-link>
          </p>
          <p class="footer-link">
            <nuxt-link to="/lainaa-15000-euroa">Lainaa 15 000 euroa</nuxt-link>
          </p>
          <p class="footer-link">
            <nuxt-link to="/lainaa-20000-euroa">Lainaa 20 000 euroa</nuxt-link>
          </p>
          <p class="footer-link">
            <nuxt-link to="/lainaa-25000-euroa">Lainaa 25 000 euroa</nuxt-link>
          </p>
          <p class="footer-link">
            <nuxt-link to="/lainaa-30000-euroa">Lainaa 30 000 euroa</nuxt-link>
          </p>
          <p class="footer-link">
            <nuxt-link to="/lainaa-40000-euroa">Lainaa 40 000 euroa</nuxt-link>
          </p>
          <p class="footer-link">
            <nuxt-link to="/lainaa-50000-euroa">Lainaa 50 000 euroa</nuxt-link>
          </p>
          <p class="footer-link">
            <nuxt-link to="/lainaa-60000-euroa">Lainaa 60 000 euroa</nuxt-link>
          </p>
        </div>
        <div v-if="showLinks" class="column is-half is-4-tablet is-3-desktop">
          <p class="footer-link-title">Lisätiedot</p>
          <p class="footer-link">
            <nuxt-link to="/tietosuojaseloste" target="_blank">Tietosuojaseloste</nuxt-link>
          </p>
          <p class="footer-link">
            <nuxt-link to="/tietoja-evasteista" target="_blank">Tietoja evästeistä</nuxt-link>
          </p>
          <aaa-svg class="footer-aaa_svg" />
        </div>
      </div>
    </div>
  </footer>
</template>

<script>

export default {
  components: {
    logoSvg: () => import( '~/assets/img/logo-white.svg?inline' ),
    aaaSvg: () => import( '~/assets/img/aaa.svg?inline' )
  },
  props: {
    showLinks: {
      type: Boolean,
      default: true
    }
  }
}
</script>
