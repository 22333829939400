<template>
  <div class="content articles-page">
    <breadcrumb
      :path="{'Talous': ''}"
    />
    <h1> Artikkeleita ja asiakastarinoita </h1>
    <p> Tällä sivulla kerromme hyödyllistä tietoa lainoista ja niiden kilpailuttamisesta. Täältä löydät myös konkreettisia kokemuksia siitä miten lainojen kilpailuttaminen ja yhdistäminen on auttanut asiakkaitamme säästämään lainanhoitokuluissa. </p>
    <br>
    <subpages />
  </div>
</template>

<script>

export default {
  layout: 'articles',
  name: 'Talous',
  components: {
    subpages: () => import( '~/components/layout/subpages' ),
    breadcrumb: () => import( '~/components/breadcrumb' )
  },
  async asyncData ( { $axios, store, app } ) {
    // Get data from strapi
    const dynamic = []
    let strapi = {}
    try {
      strapi = await $axios.$get( '/cms/articles' )
    }
    catch ( error ) {
      app.$logger.error( error )
    }
    // Parse strapi content
    Object.keys( strapi ).forEach( ( article ) => {
      dynamic.unshift( {
        subtitle: strapi[article].title,
        link: `/talous/${strapi[article].slug}`,
        image: `/cms${strapi[article].image.formats.small.url}`,
        strapi: true
      } )
    } )
    const articles = [
      ...dynamic,
      {
        subtitle: 'Kulutusluottojen korot putosivat selvästi',
        link: '/talous/kulutusluottojen-korot-putosivat-selvasti'
      },
      {
        subtitle: 'Korkokatto putosi kymmeneen prosenttiin',
        link: '/talous/korkokatto-putosi-kymmeneen-prosenttiin'
      },
      {
        subtitle: 'Kulutusluottojen lakimuutos sai suomalaiset liikkeelle',
        link: '/talous/kulutusluottojen-lakimuutos-sai-suomalaiset-liikkeelle'
      },
      {
        subtitle: 'Pienellä muutoksella voit säästää satoja euroja lainakuluissa',
        link: '/talous/mita-tekisit-200-eurolla'
      },
      {
        subtitle: 'Älä hae lainaa mistä tahansa – Näin löydät parhaan koron pienimmällä vaivalla',
        link: '/talous/ala-hae-lainaa-mista-tahansa'
      },
      {
        subtitle: 'Petri yhdisti pienet lainansa ja säästää nyt 263 euroa kuussa',
        link: '/talous/petri-yhdisti-pienet-lainansa-ja-hammastyi'
      },
      {
        subtitle: 'Katso 5 kohdan listaus, jolla helpotat oman taloutesi hoitoa',
        link: '/talous/katso-5-kohdan-listaus-jolla-helpotat-taloutesi-hoitoa'
      },
      {
        subtitle: 'Oletko jo kokeillut lainojen kilpailuttamista verkossa?',
        link: '/talous/oletko-jo-kokeillut-lainojen-kilpailuttamista-verkossa'
      },
      {
        subtitle: 'Pitkään odotettu reissu edessä ja rahat vähissä?',
        link: '/talous/pitkaan-odotettu-reissu-edessa-ja-rahat-vahissa'
      },
      {
        subtitle: 'Kansainväliset digipankit ovat myllänneet Suomen lainamarkkinat – Rahalaitoksen avulla löydät luotettavan lainantarjoajan',
        link: '/talous/kansainvaliset-digipankit-ovat-myllyttaneet-suomen-lainamarkkinat'
      },
      {
        subtitle: 'Lainojen yhdistäminen kannatti – perhe säästää yli 500 euroa kuussa!',
        link: '/talous/lainojen-yhdistaminen-kannatti'
      },
      {
        subtitle: 'Haaveiletko urheilureissusta? Verkon avulla rakennat oman unelmiesi matkan',
        link: '/talous/haaveiletko-urheilureissusta'
      },
      {
        subtitle: 'Teija yhdisti lainansa ja säästää nyt lainakuluissa satoja euroja',
        link: '/talous/teija-yhdisti-lainansa-ja-saastaa-nyt-kuluissa'
      },
      {
        subtitle: 'Auton hankinnassa voi säästää reilusti kilpailuttamalla autolainat',
        link: '/talous/auton-hankinnassa-voi-saastaa-reilusti-kilpailuttamalla'
      },
      {
        subtitle: 'Korkokatto - Vakuudettomien lainojen korkoja rajoittava laki astui voimaan',
        link: '/talous/vakuudettomien-lainojen-korkoja-rajoittava-laki-astui-voimaan'
      },
      {
        subtitle: 'Essi ja Jere hyödynsivät korkokaton | Säästävät lähes 400',
        link: '/talous/essi-ja-jere-hyodynsivat-lainojen-korkokaton'
      },
      {
        subtitle: 'Kulutusluottojen säännöt menivät uusiksi – Näin saat kaiken irti lakimuutoksesta',
        link: '/talous/kulutusluottojen-saannot-uusiksi-nain-hyodyt-korkokatosta'
      }
    ]
    // Make first article bigger.
    articles[0].width = 8
    await store.dispatch( 'subpage/setPages', articles )
  },
  head () {
    return {
      title: 'Artikkeleita lainaamisesta | Rahalaitos',
      meta: [
        { hid: 'description', name: 'description', content: 'Täältä löydät konkreettisia kokemuksia siitä miten lainojen kilpailuttaminen ja yhdistäminen on auttanut asiakkaitamme säästämään rahaa.' }
      ]
    }
  }
}
</script>

<style lang="scss">
  .articles-page {
    .loan-subpages-bg {
      height: 320px !important;
    }
    .loan-subpages-title {
      padding: 2rem 0 !important;
    }
  }
</style>
