<template>
  <div v-tooltip="{ content: tooltip }" class="field">
    <datepicker
      ref="picker"
      :key="name"
      v-model="date"
      :disabled="isStatic"
      :disabled-dates="disabledDates"
      :format="formatDate"
      :language="fi"
      :initial-view="initialView"
      :input-class="{ 'input' : true, 'is-danger': error, 'is-static': isStatic, 'placeholder-not-shown': !hasValue }"
      :maximum-view="maximumView"
      :minimum-view="minimumView"
      :monday-first="true"
      :name="name"
      :placeholder="placeholder"
      :value="date"
      wrapper-class="control has-float-label"
    >
      <label slot="afterDateInput" :for="name" class="label" @click="openCalendar">
        {{ label }}
      </label>
    </datepicker>
    <transition name="slideDown">
      <p v-if="error" class="help is-danger">
        {{ error }}
      </p>
    </transition>
  </div>
</template>

<script>
// @todo beforeCalendarHeader slot
import { fi } from 'vuejs-datepicker/src/locale'
import { format, parse } from 'date-fns'

export default {
  $_veeValidate: {
    value () {
      return this.value
    },
    name () {
      return this.name
    },
    alias () {
      return this.label || this.placeholder || this.name
    },
    events: 'input'
  },
  components: {
    Datepicker: () => import( 'vuejs-datepicker/src/components/Datepicker' ),
    formInput: () => import( '~/components/form/input' )
  },
  props: {
    name: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    placeholder: {
      type: String,
      default: ' '
    },
    tooltip: {
      type: String,
      default: ''
    },
    header: {
      type: String,
      default: ''
    },
    value: {
      type: [String, Date],
      default: null
    },
    error: {
      type: String,
      default: ''
    },
    isStatic: {
      type: Boolean,
      default: false
    },
    initialView: {
      type: String,
      default: 'minimumView'
    },
    minimumView: {
      type: String,
      default: 'day'
    },
    maximumView: {
      type: String,
      default: 'year'
    },
    disabledDates: {
      type: Object,
      default () {
        return {
          from: new Date()
        }
      }
    },
    format: {
      type: String,
      default: 'MM/yyyy'
    }
  },
  data () {
    return {
      fi,
      date: null
    }
  },
  computed: {
    hasValue () {
      return this.value !== ''
    }
  },
  watch: {
    date () {
      this.$emit( 'input', this.formatDate( this.date ) )
    },
    value ( value ) {
      this.date = this.parseDate( value )
    }
  },
  mounted () {
    if ( this.value && /\d/.test( this.value ) ) {
      this.date = this.parseDate( this.value )
    }
  },
  methods: {
    openCalendar () {
      if ( !this.isStatic ) this.$refs.picker.showCalendar()
    },
    formatDate ( date ) {
      let result
      try {
        result = format( date, this.format )
      }
      // When formatting fails try to parse the date first
      catch ( error ) {
        result = format( this.parseDate( date ), this.format )
      }
      return result
    },
    /**
     * Parse the date into current format.
     * When it fails try some exceptions.
     */
    parseDate ( date ) {
      let result = parse( date, this.format, new Date() )
      // When invalid date try some exceptions
      if ( isNaN( result.getTime() ) ) {
        // Convert consumer field to business
        if ( this.name === 'rekpaiva' && this.format.length === 4 ) {
          result = parse( date, 'dd.MM.yyyy', new Date() )
        }
        // Convert business field to consumer
        else if ( this.name === 'rekpaiva' && this.format.length === 10 ) {
          result = parse( date, 'yyyy', new Date() )
        }
        else {
          result = new Date()
          this.$logger.warning( 'Unable to parse date', { date } )
        }
      }
      return result
    }
  }
}
</script>

<style lang="scss">
  .vdp-datepicker__calendar {
    border-radius: $control-radius !important;
    .cell {
      &:not(.blank):not(.disabled) {
        &.day:hover,
        &.month:hover,
        &.year:hover {
          border: 1px solid $rl-green !important;
          border-radius: $control-radius !important;
        }
      }
      &.selected {
        background: $rl-green !important;
        border-radius: $control-radius !important;
        color: $white !important;
        &:hover,
        &.highlighted {
          background: $rl-green !important;
          border-radius: $control-radius !important;
        }
      }
    }
  }

  .vdp-datepicker {
    input.is-static {
      color: $text;
      cursor: text;
    }
  }
  .modal-card {
    .vdp-datepicker__calendar {
      width: 100%;
      .cell {
        font-size: 0.8em;
      }
    }
  }
</style>
